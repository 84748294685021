import { LOCALE_CODES } from '@yescapa-dev/ysc-components/i18n'

export const useRentalBaseURL = () => {
  const { locale } = useI18n()
  const { DE, EN, IT, PT, FR, ES, NL } = LOCALE_CODES as Record<string, string>

  const rentalBaseURLMap = {
    [DE]: 'https://www.yescapa.de',
    [EN]: 'https://www.yescapa.co.uk',
    [IT]: 'https://www.yescapa.it',
    [PT]: 'https://www.yescapa.pt',
    [FR]: 'https://www.yescapa.fr',
    [ES]: 'https://www.yescapa.es',
    [NL]: 'https://www.yescapa.nl',
    default: 'https://www.yescapa.com',
  }

  return new URL(rentalBaseURLMap[locale.value] || rentalBaseURLMap.default)
}
